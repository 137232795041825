import { BigNumber, ethers } from 'ethers';

export const formatWalletAddress = (
  address: string | undefined | null,
  firstBreakpoint = 6,
  secondBreakpoint = 4
): string => {
  if (!address) {
    return '';
  }

  if (address.length > 35) {
    return `${address.slice(0, firstBreakpoint)}...${address.slice(
      address.length - secondBreakpoint,
      address.length
    )}`;
  }

  return address;
};

export function dFormatter(n: number, toFixed = 0): string {
  return n % 1 !== 0 ? `${n.toFixed(toFixed)}` : n.toFixed();
}

export function kFormatter(
  n: number,
  toFixed = 0,
  skip?: ('k' | 'm')[]
): string {
  if (n === undefined) {
    return '0';
  }

  if (n >= 1000000000) {
    return `${(n / 1000000000).toFixed(1).replace(/\.0$/, '')}B`;
  }

  if (n >= 1000000 && !skip?.includes('m')) {
    return `${(n / 1000000).toFixed(1).replace(/\.0$/, '')}M`;
  }

  if (n >= 1000 && !skip?.includes('k')) {
    return `${(n / 1000).toFixed(1).replace(/\.0$/, '')}K`;
  }

  return toOptionalFixed(n, toFixed);
}

export function kFormatter2(n: number, toFixed = 3): string {
  if (n === undefined) {
    return '0';
  }

  if (n >= 1_000_000_000) {
    return `${(n / 1_000_000_000).toFixed(toFixed).replace(/\.0$/, '')}B`;
  }

  if (n >= 1_000_000) {
    return `${(n / 1_000_000).toFixed(toFixed).replace(/\.0$/, '')}M`;
  }

  return n.toLocaleString('en-US', {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2
  });
}

export const parseUnits = (amount: string, decimals: number) => {
  return ethers.utils.parseUnits(amount, decimals).toString();
};

// DEPRECATED
// migrate to formatUnits from ethers/utils
export function bigNumberToDecimal(
  num: BigNumber | [BigNumber],
  decimals: number
) {
  const n = Array.isArray(num) ? num[0] : num;

  return Number(n.toString()) / 10 ** decimals;
}

export function bigNumberToDecimalV2(
  num: BigNumber | [BigNumber],
  decimals: number
) {
  const n = Array.isArray(num) ? num[0] : num;

  return ethers.utils.formatUnits(n, decimals);
}

// DEPRECATED
// migrate to parseUnits from ethers/utils
export function numberToBigNumberFixed(n: number, decimals: number): string {
  return BigInt(Math.round(n * Math.pow(10, decimals))).toString();
}

export function numberToBigNumber(n: string, decimals: number): BigNumber {
  return ethers.utils.parseUnits(n, decimals);
}

export function valueToUintString(amount: string, decimals: number): string {
  return amount.padEnd(amount.length + decimals, '0');
}

export const toOptionalFixed = (num: number | undefined, digits: number) => {
  if (num === undefined) {
    return '';
  }

  const minValue = Number(
    `0.${new Array(digits - 1 < 0 ? 0 : digits - 1).fill(0).join('')}1`
  );

  if (+num === 0) {
    return '0';
  }

  if (num < minValue) {
    return `<${minValue}`;
  }

  if (num === minValue) {
    return `${num.toLocaleString('en', {
      minimumFractionDigits: 2
    })}`;
  }

  const val = Number.parseFloat(num.toFixed(digits));

  return `${val.toLocaleString('en', {
    minimumFractionDigits: 2
  })}`;
};

export const decToFrac = (dec: number) =>
  [...Array(1000).keys()]
    .flatMap(i =>
      [...Array(1000).keys()].map(j => [
        i + 1,
        j + 1,
        (i + 1) / (j + 1),
        Math.abs((i + 1) / (j + 1) - dec)
      ])
    )
    .sort((a, b) => a[3] - b[3])[0]
    .slice(0, 2);

export function getLastDayTimestamp() {
  const today = new Date();
  const yesterday = new Date(today);

  yesterday.setDate(today.getDate() - 1);

  const timeStamp = Math.floor(yesterday.getTime() / 1000);

  return timeStamp;
}

export function formatNumber(val: number, maximumFractionDigits = 3) {
  return new Intl.NumberFormat('en-US', { maximumFractionDigits }).format(val);
}
