import axios, { AxiosResponse } from 'axios';
import { useQuery } from '@tanstack/react-query';
import { QueryKeys } from '@app/constants/queryKeys';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';

type AirdropDetails = {
  id: string;
  from: number;
  to: number;
  tokens: Record<string, { name: string; amount: string }>;
};

async function fetcher(
  airdropId: string,
  config: ApplicationConfig | undefined
) {
  const { data } = await axios.get<void, AxiosResponse<AirdropDetails>>(
    `${config?.URLS.middlewareUrl}/airdrop/${airdropId}`
  );

  return {
    ...data,
    from: data.from * 1000,
    to: data.to * 1000
  };
}

export function useAirdropDetails(airdropId: string, isActive: boolean) {
  const config = useConfig();

  return useQuery(
    [QueryKeys.AIRDROP_TOKEN_DETAILS, airdropId, !!config],
    () => {
      return fetcher(airdropId, config);
    },
    {
      enabled: !!(isActive && config),
      refetchOnWindowFocus: false
    }
  );
}
