import { getActiveChain } from '@app/constants/chains';

export const EMPTY_ADDRESS = '0x0000000000000000000000000000000000000000';
export const USER_LOCATION_WARNING = 'KIM_USER_USER_LOCATION_WARNING';
export const ACTIVE_CHAIN = 'ACTIVE_CHAIN';

export const APP_NAME = 'Scribe';

export const DEFAULT_CHAIN_ID = getActiveChain().chainId;
export const DEFAULT_NATIVE_SYMBOL = 'ETH';
export const DEFAULT_NATIVE_NAME = 'Ether';

export const MAX_UINT128 = 340282366920938463463374607431768211455n;

export const KIM_REF_CODES = 'KIM_REF_CODES';

export const HIDDEN_NITRO_POOLS = [
  '0x8d37aa4a4d6eef6fc340292e669c6a78356cad2f'
];
