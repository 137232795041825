import React, { useState } from 'react';
import { Box, Button } from '@chakra-ui/react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { useAuthContext } from '@app/context/AuthContext/AuthContext';
import { Tooltip } from '@app/components/Tooltip';
import { useAsync } from 'react-use';
import { useAddress } from '@thirdweb-dev/react';
import { KIM_REF_CODES } from '@app/constants/common';

import s from './ReferralCodeBanner.module.scss';
import { useConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

const MobileProps = {};

export const ReferralCodeBanner = ({
  type = 'normal'
}: {
  type?: string | undefined;
}) => {
  const config = useConfig();
  const address = useAddress();
  const chainId = useSupportedChain()?.chainId;
  const { user, eligibleToReferral, login } = useAuthContext();

  const { value: referralCode } = useAsync(async () => {
    if (!address) {
      return;
    }

    const exisitng = localStorage.getItem(KIM_REF_CODES);

    let existingCodes: Record<string, string> = {};

    if (exisitng) {
      existingCodes = JSON.parse(exisitng);

      return existingCodes[address];
    }
  }, [address]);

  const { value } = useAsync(async () => {
    if (!user) {
      return;
    }

    return `${location?.origin}?referralCode=${user.referralCode}`;
  }, [user]);

  const [isCopyFrom, setIsCopyFrom] = useState(false);

  if (isCopyFrom) {
    setTimeout(() => {
      setIsCopyFrom(false);
    }, 2500);
  }

  if (!config?.FLAGS.showReferralsPage) {
    return null;
  }

  if (!user && eligibleToReferral?.isEligible) {
    return (
      <Box
        sx={{ position: 'relative' }}
        {...(type === 'mobile' && MobileProps)}
      >
        <Tooltip tooltip="Refer and earn more points">
          <Button
            variant="primary"
            sx={{
              background: 'transparent',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: 'brand.primary',
              fontSize: 16,
              color: 'brand.primary'
            }}
            py="3px"
            px="12px"
            onClick={() => login({ address, referralCode, chainId })}
          >
            Activate referral code
          </Button>
        </Tooltip>
      </Box>
    );
  }

  if (!user && eligibleToReferral?.error === 'KimActivityValidationError') {
    return (
      <Box
        sx={{ position: 'relative' }}
        {...(type === 'mobile' && MobileProps)}
      >
        <Tooltip tooltip="Activates after swap or LP">
          <Button
            sx={{
              background: 'transparent',
              borderRadius: '8px',
              border: '1px solid',
              borderColor: 'white',
              fontSize: 16,
              color: 'white'
            }}
            variant="primary"
            py="3px"
            px="12px"
          >
            Activate referral code
          </Button>
        </Tooltip>
      </Box>
    );
  }

  if (!value || !user) {
    return null;
  }

  return (
    <Box sx={{ position: 'relative' }} {...(type === 'mobile' && MobileProps)}>
      <Tooltip
        tooltip="Copied"
        visible={isCopyFrom}
        triggerClassName={s.tooltip}
      >
        <CopyToClipboard
          text={value}
          onCopy={() => {
            setIsCopyFrom(true);
          }}
        >
          <Button variant="secondary" py="8px" px="12px">
            Referral code: {user.referralCode}
          </Button>
        </CopyToClipboard>
      </Tooltip>
    </Box>
  );
};
