import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Button, Text } from '@chakra-ui/react';
import React, { FC } from 'react';
import styles from '@app/components/AppHeader/components/MobileMenu/MobileMenu.module.scss';
import { APP_ROUTES } from '@app/constants/routes';
import Link from 'next/link';
import ExpandableOption from '@app/components/AppHeader/components/DesktopMenu/ExpandableOption';
import AirdropClaimBanner from '@app/components/AirdropClaimBanner';
import { APP_NAME } from '@app/constants/common';
import { ApplicationConfig } from '@app/config/types';
import { useConfig } from '@app/config';

export const mobileMenuItems = (config: ApplicationConfig | undefined) => [
  // {
  //   label: 'Swap',
  //   link: APP_ROUTES.swapPage,
  //   show: true
  // },
  // {
  //   label: 'Positions',
  //   link: APP_ROUTES.positions,
  //   show: true
  // },
  {
    label: 'Liquidity',
    link: APP_ROUTES.addLiquidityV4,
    show: true,
    description: 'Create positions into pools to earn swap fees'
  },
  {
    label: 'Leaderboard',
    link: APP_ROUTES.leaderboard,
    show: config?.FLAGS.showLeaderboardPage,
    description: 'SCRIBE points leaderboard'
  },
  {
    label: 'Dosirak',
    link: APP_ROUTES.dosirak,
    show: config?.FLAGS.showDosirakPage,
    description: 'Dosirak Loot Boxes'
  },
  {
    label: 'Partners',
    link: APP_ROUTES.partners,
    show: config?.FLAGS.showPartnersPage,
    description: `Meet the Official Partners of ${APP_NAME}`
  },
  {
    label: 'Nitro',
    link: APP_ROUTES.nitroPools,
    show: config?.FLAGS.showNitroPage,
    description:
      'Deposit your staked positions into a compatible Nitro pool and earn additional rewards'
  },
  // {
  //   label: 'Dashboard',
  //   link: APP_ROUTES.kim,
  //   show: config.showRyoPages === 'true'
  // },
  {
    label: 'Rewards',
    link: APP_ROUTES.rewards,
    show: config?.FLAGS.showRewardsPage,
    description: 'Manage your xKim plugins allocations.'
  },
  {
    label: 'Yield Booster',
    link: APP_ROUTES.boost,
    show: config?.FLAGS.showBoosterPage,
    description:
      'Allocate KIM here to increase the yield of your staking positions up to +100%'
  }
];
export const mobileMenuItems2 = (config: ApplicationConfig | undefined) => [
  {
    label: 'Swap',
    link: APP_ROUTES.swapPage,
    show: true
  },
  {
    label: 'Earn',
    show: config?.FLAGS.showRyoPages,
    items: [
      {
        label: 'Positions',
        link: APP_ROUTES.positions,
        show: true
      },
      {
        label: 'Pools',
        link: APP_ROUTES.poolsPage,
        show: true
      },
      {
        label: 'Liquidity',
        link: APP_ROUTES.addLiquidityV4,
        show: true
      }
    ]
  },
  {
    label: 'xKIM',
    show: config?.FLAGS.showRyoPages,
    items: [
      {
        label: 'Dashboard',
        link: APP_ROUTES.kim,
        show: true
      },
      {
        label: 'Rewards',
        link: APP_ROUTES.rewards,
        show: config?.FLAGS.showRewardsPage
      },

      {
        label: 'Yield Booster',
        link: APP_ROUTES.boost,
        show: config?.FLAGS.showBoosterPage
      }
    ]
  },
  {
    label: 'Leaderboard',
    link: APP_ROUTES.leaderboard,
    show: config?.FLAGS.showLeaderboardPage
  },

  {
    label: 'Referrals',
    link: APP_ROUTES.referrals,
    show: config?.FLAGS.showReferralsPage
  },
  {
    label: 'Info',
    link: APP_ROUTES.info,
    show: config?.FLAGS.showInfoPage
  },
  {
    label: 'Partners',
    link: APP_ROUTES.partners,
    show: config?.FLAGS.showPartnersPage
  }
];

export const menuItems = (config: ApplicationConfig | undefined) => [
  {
    label: 'Leaderboard',
    link: APP_ROUTES.leaderboard,
    show: config?.FLAGS.showLeaderboardPage
  },
  {
    label: 'Partners',
    link: APP_ROUTES.partners,
    show: config?.FLAGS.showPartnersPage
  },
  {
    label: 'KIM',
    show: config?.FLAGS.showRyoPages,
    items: [
      {
        label: 'Dashboard',
        link: APP_ROUTES.kim,
        show: true
      },
      {
        label: 'Rewards',
        link: APP_ROUTES.rewards,
        show: config?.FLAGS.showRewardsPage
      }
    ]
  }
];

export const DesktopMenu: FC = () => {
  const config = useConfig();

  return (
    <>
      <Menu placement="bottom-start">
        <MenuButton as={Button}>
          <svg className={styles.burger}>
            <line x1={0} x2={24} y1={4} y2={4} />
            <line x1={0} x2={24} y1={12} y2={12} />
            <line x1={0} x2={24} y1={20} y2={20} />
          </svg>
        </MenuButton>
        <MenuList
          sx={{
            backgroundColor: 'gray.2',
            borderRadius: '8px',
            minWidth: '300px',
            overflow: 'hidden',
            display: 'flex',
            flexDirection: 'column',
            padding: '32px'
          }}
        >
          {menuItems(config).map(item => {
            const isLink = !!item.link;

            if (isLink && !item.items) {
              return (
                <MenuItem
                  key={item.label}
                  display={item.show ? 'flex' : 'none'}
                  mb="20px"
                >
                  <Link href={item.link}>
                    <Text
                      color="gray.15"
                      fontSize="20px"
                      fontWeight={500}
                      _hover={{ color: 'orange' }}
                    >
                      {item.label}
                    </Text>
                  </Link>
                </MenuItem>
              );
            }

            return <ExpandableOption item={item} key={item.label} />;
          })}
          {config?.FLAGS.showAirdropClaim && (
            <MenuItem display={'flex'} mt="20px">
              <AirdropClaimBanner />
            </MenuItem>
          )}
        </MenuList>
      </Menu>
    </>
  );
};
