import { CopyIcon } from '@chakra-ui/icons';
import { Link, Tooltip, useBoolean, LinkProps } from '@chakra-ui/react';
import { useCallback } from 'react';

import type { Placement } from '@popperjs/core';

interface Props extends LinkProps {
  text: string;
  color?: string;
  copyText?: string;
  placement?: Placement;
}

const CLOSE_DELAY = 500;

export const CopyButton = ({
  text,
  copyText = 'Copied',
  placement,
  color,
  ...props
}: Props) => {
  const [isTooltipOpen, setIsTooltipOpened] = useBoolean();
  const handleCopyText = useCallback(
    // @ts-ignore
    e => {
      e.stopPropagation();
      navigator.clipboard.writeText(text);
      setIsTooltipOpened.on();
    },
    [setIsTooltipOpened, text]
  );

  return (
    <Tooltip
      label={copyText}
      isOpen={isTooltipOpen}
      closeDelay={200000000000}
      placement={placement}
      onClose={setIsTooltipOpened.off}
      portalProps={{ appendToParentPortal: true }}
      zIndex={1000}
    >
      <Link onClick={handleCopyText} {...props}>
        <CopyIcon
          w="20px"
          h="20px"
          color={color ?? 'neutral.400'}
          _hover={{ color: 'white', cursor: 'pointer' }}
        />
      </Link>
    </Tooltip>
  );
};
