import { useChain } from '@thirdweb-dev/react';

export function useChainExplorer() {
  const chain = useChain();

  const explorer = chain?.explorers
    ? chain?.explorers[0]
    : { url: 'https://scrollscan.com/' };

  return { explorer };
}
