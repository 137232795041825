import Link from 'next/link';
import React, { FC, PropsWithChildren } from 'react';
import Logo from '@app/assets/images/logo/mainLogo.svg';
import LogoSmall from '@app/assets/images/logo/mainLogoSmall.svg';

import { useMedia } from 'react-use';

import { Account } from '@app/components/AppHeader/components/Account';
import { Container, ContentWrapper } from '@app/components/AppHeader/styled';

import s from './AppHeader.module.scss';
import { LocationWarningBanner } from '@app/components/LocationWarningBanner';
import { NetworkSelector } from '@app/components/NetworkSelector';
import { HStack, Flex, Center, Text } from '@chakra-ui/react';
import { useConfig } from '@app/config';
import AppAnnouncement from '@app/components/AppAnnouncement/AppAnnouncement';

export const AppHeader: FC<PropsWithChildren> = ({ children }) => {
  const isMobile = useMedia('(max-width: 992px)', true);
  const config = useConfig();

  return (
    <Container className={s.root}>
      <LocationWarningBanner />
      <AppAnnouncement
        content={
          <Link
            href="https://app.hedgey.finance/investor-lockups"
            target="_blank"
            rel="noreferrer"
          >
            Public Sale Tokens Click Here!
          </Link>
        }
      />
      <ContentWrapper className={s.wrapper}>
        {isMobile ? (
          <>
            <Link href="/">
              <HStack>
                <LogoSmall />
              </HStack>
            </Link>
            <HStack ml="auto">
              <NetworkSelector />
              <Account />
            </HStack>
          </>
        ) : (
          <>
            <HStack
              justifyContent="flex-start"
              alignItems="center"
              flex={1}
              gap={10}
            >
              {children}
            </HStack>
            {!isMobile && (
              <HStack flex={1} justifyContent="center">
                <Link href="/">
                  <Flex align="center" gap={2}>
                    <Logo />
                  </Flex>
                </Link>
              </HStack>
            )}
            <HStack flex={1} justifyContent="flex-end">
              <NetworkSelector />
              <Account />
            </HStack>
          </>
        )}
      </ContentWrapper>
    </Container>
  );
};
