import { Box, Divider, HStack, Text, VStack } from '@chakra-ui/react';
import { format } from 'date-fns';
import React, { FC, useMemo } from 'react';
import { useToggle } from 'react-use';

import { CopyButton } from '@app/components/CopyButton';
import { ExternalLink } from '@app/components/ExternalLink';
import { TokensPair } from '@app/components/TokensPair';
import { InfoRow } from '@app/components/TransactionsHistory/components/InfoRow';
import { formatWalletAddress, toOptionalFixed } from '@app/helpers/format';
import { Mint, MintV4 } from '@app/types/transaction';

interface Props {
  mints: MintV4[];
  timestamp: string;
  txId: string;
}

export const AddLiquidityTransactionV4: FC<Props> = ({
  txId,
  mints,
  timestamp
}) => {
  const [open, toggleOpen] = useToggle(false);

  const { tokenFrom, tokenTo, amount0, amount1 } = useMemo(() => {
    const _mint = mints[0];

    return {
      tokenFrom: _mint.pool.token0,
      tokenTo: _mint.pool.token1,
      amount0: toOptionalFixed(+_mint.amount0, 5),
      amount1: toOptionalFixed(+_mint.amount1, 5)
    };
  }, [mints]);

  return (
    <Box
      sx={{
        backgroundColor: 'gray.10',
        padding: '16px 20px',
        borderRadius: '6px',
        mb: '16px'
      }}
      onClick={toggleOpen}
    >
      <HStack width="100%" gap="12px" cursor="pointer" userSelect="none">
        <TokensPair
          token0Symbol={tokenFrom.symbol}
          token1Symbol={tokenTo?.symbol}
        />
        <HStack width="100%" alignItems="center" justifyContent="space-between">
          <VStack alignItems="flex-start" gap={1}>
            <Text
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'whiteOpacity.50'
              }}
              fontFamily="p"
            >
              Added liquidity
            </Text>
            <Text
              sx={{
                fontSize: '14px',
                fontWeight: 400,
                color: 'neutral.200'
              }}
              fontFamily="p"
            >
              {tokenFrom.symbol}/{tokenTo.symbol}
            </Text>
          </VStack>

          <VStack alignItems="flex-end">
            <Text
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'whiteOpacity.50'
              }}
              fontFamily="p"
            >
              {amount0} {tokenFrom.symbol}
            </Text>
            <Text
              sx={{
                fontSize: '12px',
                fontWeight: 400,
                color: 'neutral.200'
              }}
              fontFamily="p"
            >
              {amount1} {tokenTo.symbol}
            </Text>
          </VStack>
        </HStack>
      </HStack>
      {open && (
        <>
          <Divider
            sx={{
              width: 'calc(100% + 40px)',
              height: '1px',
              background: 'whiteOpacity.10',
              margin: '16px -20px'
            }}
          />
          <Box>
            <InfoRow
              label={`Date and time`}
              value={`${format(
                new Date(+timestamp * 1000),
                'dd MMM, yyyy, hh:mm a'
              )}`}
            />
            <InfoRow
              label={`Transaction hash`}
              value={
                <HStack>
                  <Text
                    sx={{
                      fontSize: '12px',
                      fontWeight: 400,
                      color: 'whiteOpacity.50'
                    }}
                  >
                    {formatWalletAddress(txId)}
                  </Text>
                  <CopyButton color="orange" text={txId} />
                </HStack>
              }
            />
            <InfoRow label={<ExternalLink txHash={txId} />} value="" />
          </Box>
        </>
      )}
    </Box>
  );
};
