import { AspectRatio, ChakraProps, Circle, Text } from '@chakra-ui/react';
import Image from 'next/image';
import React, { FC, useMemo } from 'react';

import { TOKEN_ICONS } from '@app/constants/tokens';

interface Props extends ChakraProps {
  symbol?: string;
  iconUrl?: string | null;
  width?: string;
  height?: string;
}

export const TokenIcon: FC<Props> = ({
  symbol,
  iconUrl,
  width = '24px',
  height = '24px',
  ...rest
}) => {
  const url = useMemo(() => {
    if (iconUrl) {
      return iconUrl;
    }

    if (symbol && TOKEN_ICONS[symbol.toLowerCase()]) {
      return TOKEN_ICONS[symbol?.toLowerCase()];
    }

    return null;
  }, [iconUrl, symbol]);

  return (
    <AspectRatio
      ratio={1}
      w={width}
      h={height}
      pos="relative"
      borderRadius="100%"
      overflow="hidden"
      border="1px solid"
      borderColor="neutral.400"
      {...rest}
    >
      {url ? (
        <Image src={url} alt="Token Icon" fill />
      ) : (
        <Circle size="100%" bg="neutral.400" color="black">
          <Text fontSize="10px" fontWeight={600}>
            {symbol?.slice(0, 3)}
          </Text>
        </Circle>
      )}
    </AspectRatio>
  );
};

export default TokenIcon;
