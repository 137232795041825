import {
  createContext,
  FC,
  PropsWithChildren,
  useCallback,
  useContext,
  useMemo
} from 'react';
import { UserAlertModal } from '@app/components/UserAlertModal';
import { useAsync, useToggle } from 'react-use';
import localforage from 'localforage';
import { USER_LOCATION_WARNING } from '@app/constants/common';

interface ILocationContext {
  isReadOnly: boolean;
  toggleShowLocationWarning: () => void;
}

const LocationContext = createContext<ILocationContext>({
  isReadOnly: false,
  toggleShowLocationWarning: () => {}
});

interface Props extends PropsWithChildren {
  query: { country: string; city: string; region: string };
}

export function useLocationContext() {
  const context = useContext(LocationContext);

  if (context === undefined) {
    throw new Error(
      'useLocationContext must be used withing LocationContextProvider'
    );
  }

  return context;
}

export const LocationContextProvider: FC<Props> = ({ query, children }) => {
  const [showLocationWarning, toggleShowLocationWarning] = useToggle(false);

  const handleWarningClose = useCallback(async () => {
    try {
      await localforage.setItem(USER_LOCATION_WARNING, {
        locationWarningClosed: true
      });
      toggleShowLocationWarning(false);
    } catch (err) {
      console.error(err);
    }
  }, [toggleShowLocationWarning]);

  const contextValue = useMemo(() => {
    return {
      isReadOnly: query.country === 'US',
      toggleShowLocationWarning
    };
  }, [query.country, toggleShowLocationWarning]);

  useAsync(async () => {
    try {
      const data = await localforage.getItem<{
        locationWarningClosed: boolean;
      }>(USER_LOCATION_WARNING);

      if (
        !data?.locationWarningClosed &&
        query.country === 'US' &&
        !showLocationWarning
      ) {
        toggleShowLocationWarning(true);
      }

      if (data?.locationWarningClosed && query.country !== 'US') {
        await localforage.removeItem(USER_LOCATION_WARNING);
      }
    } catch (err) {
      console.error(err);
    }
  }, [query.country, showLocationWarning]);

  return (
    <LocationContext.Provider value={contextValue}>
      {children}
      <UserAlertModal
        title="Our product is unavailable in your location"
        message="Sorry, our products are unavailable in your location"
        isOpen={showLocationWarning}
        onClose={handleWarningClose}
      />
    </LocationContext.Provider>
  );
};
