import { Box, Skeleton } from '@chakra-ui/react';
import styled from '@emotion/styled';
import { ConnectWallet, useConnectionStatus } from '@thirdweb-dev/react';
import React from 'react';
import { useMedia } from 'react-use';
import styles from './Account.module.scss';

import { ConnectWalletWelcomeScreen } from '@app/components/AppHeader/components/ConnectWalletWelcomeScreen';
import { ConnectedWallet } from '@app/components/ConnectedWallet';

const MobileWalletBtn = styled.div`
  background: var(--brand-white);
  border: 1px solid var(--brand-black);
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 14px !important;
  padding: 8px !important;

  &:hover {
    color: var(--brand-orange);
  }
`;

export const Account = () => {
  const connectionStatus = useConnectionStatus();
  const isMobile = useMedia('(max-width: 960px)', true);

  function renderContent() {
    if (connectionStatus === 'connecting' || connectionStatus === 'unknown') {
      return <Skeleton width="146px" height="44px" />;
    }

    if (connectionStatus === 'connected') {
      return <ConnectedWallet />;
    }

    return (
      <ConnectWallet
        switchToActiveChain
        theme="dark"
        modalSize="wide"
        className={styles['custom-wallet-button']}
        welcomeScreen={() => {
          return <ConnectWalletWelcomeScreen />;
        }}
        detailsBtn={
          isMobile ? () => <MobileWalletBtn>M</MobileWalletBtn> : undefined
        }
      />
    );
  }

  return <Box>{renderContent()}</Box>;
};
