import { useMemo } from 'react';

import { useFactoryData } from '@app/hooks/pools/useFactoryData';
import { useTokenPriceV3 } from '@app/hooks/token/useTokenPriceV3';
import { useConfig } from '@app/config';

export function useTotalTvl(): {
  tvl: number;
  isLoading: boolean;
  totalVolumeUSD: string | undefined;
} {
  const config = useConfig();
  const { data, isLoading: loadingFactoryData } = useFactoryData({
    id: config?.CONTRACTS.FACTORY ?? ''
  });

  const { data: price, isLoading: loadingPrice } = useTokenPriceV3({
    id: 'weth'
  });

  const tvl = useMemo(() => {
    if (!price || !data) {
      return 0;
    }

    return +data.totalLiquidityETH * +price;
  }, [data, price]);

  return {
    tvl,
    isLoading: loadingPrice || loadingFactoryData,
    totalVolumeUSD: data?.totalVolumeUSD
  };
}
