import { useQuery } from '@tanstack/react-query';
import axios, { AxiosResponse } from 'axios';

import { QueryKeys } from '@app/constants/queryKeys';
import { useAddress } from '@thirdweb-dev/react';
import { useConfig } from '@app/config';
import { ApplicationConfig } from '@app/config/types';

export type UserWallet = {
  id: string;
  referralCode: string;
  invitedBy: string;
  createdAt: string;
  updatedAt: string;
};

export async function fetcher(
  address: string | undefined,
  config: ApplicationConfig | undefined
) {
  if (!address) {
    return;
  }

  const { data } = await axios.get<void, AxiosResponse<UserWallet | string>>(
    `${config?.URLS.middlewareUrl}/wallets/${address}`
  );

  return data;
}

export const useUserWallet = () => {
  const address = useAddress();
  const config = useConfig();

  return useQuery<UserWallet | string | undefined>(
    [QueryKeys.USER_WALLET, { address }],
    async () => {
      return await fetcher(address, config);
    },
    {
      enabled: !!(address && config?.URLS.middlewareUrl),
      // staleTime: 10000,
      // refetchOnMount: false,
      refetchOnWindowFocus: false
      // refetchOnReconnect: false
    }
  );
};
