import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Box, Center, IconButton, Text } from '@chakra-ui/react';
import { FaChevronLeft, FaChevronRight } from 'react-icons/fa';
import { Tooltip } from '@app/components/Tooltip';

import s from './CtaBanner.module.scss';
import clsx from 'clsx';

const titleStyles = {
  fontSize: 18,
  fontWeight: 600,
  color: 'neutral.200',
  textTransform: 'uppercase',
  textAlign: 'center',
  fontFamily: 'p'
};

const CTA_ITEMS = [
  {
    id: 0,
    title: (
      <Tooltip
        tooltip={
          <Box fontFamily="p">
            <Text as="div" color="neutral.200">
              &bull;
              <Text as="span" sx={{ color: 'brand.primary' }}>
                3x
              </Text>
              &nbsp;points on&nbsp;
              <Text as="span" sx={{ color: 'brand.primary' }}>
                ETH - USDC
              </Text>
            </Text>

            <Text as="div" color="neutral.200">
              &bull;
              <Text as="span" sx={{ color: 'brand.primary' }}>
                2x
              </Text>
              &nbsp;points on&nbsp;
              <Text as="span" sx={{ color: 'brand.primary' }}>
                USDC - USDT
              </Text>
            </Text>

            <Text as="div" color="neutral.200">
              &bull;
              <Text as="span" sx={{ color: 'brand.primary' }}>
                2x
              </Text>
              &nbsp;points on&nbsp;
              <Text as="span" sx={{ color: 'brand.primary' }}>
                weETH - ETH
              </Text>
            </Text>
          </Box>
        }
      >
        <Text as="span" sx={titleStyles}>
          <Text as="span" sx={{ color: 'brand.primary' }}>
            Boosted Scribe points
          </Text>{' '}
          for the next two weeks
        </Text>
      </Tooltip>
    )
  },
  {
    id: 1,
    title: (
      <Text as="span" sx={titleStyles}>
        <Text as="span" sx={{ color: 'brand.primary' }}>
          Boosted Scroll Marks
        </Text>{' '}
        for all current LPs
      </Text>
    )
  }
];

export const CtaBanner = () => {
  const rootRef = useRef<HTMLDivElement | null>(null);
  const [active, setActive] = useState(0);

  const ctaItems = useMemo(() => {
    return CTA_ITEMS.filter(() => {
      return true;
    });
  }, []);

  useEffect(() => {
    const timer = setInterval(() => {
      setActive(prevIndex => (prevIndex + 1) % ctaItems.length);
    }, 6000);

    return () => clearInterval(timer); // Cleanup on unmount
  }, [ctaItems.length]);

  if (!ctaItems.length) {
    return null;
  }

  return (
    <Box
      sx={{
        backgroundColor: 'brand.black',
        width: '100%',
        margin: '0 auto',
        pb: 5,
        zIndex: 0,
        borderBottom: '0.5px solid',
        borderColor: 'neutral.200'
      }}
      ref={rootRef}
    >
      <Center
        sx={{
          backgroundColor: 'neutral.700',
          p: 5,
          justifyContent: 'space-between',
          width: '100%',
          gap: 6,
          minHeight: '64.25px'
        }}
      >
        <IconButton
          aria-label="left"
          _hover={{
            filter: 'brightness(120%)'
          }}
          onClick={() =>
            setActive(prev => {
              if (prev === 0) {
                return ctaItems.length - 1;
              }

              return prev - 1;
            })
          }
        >
          <FaChevronLeft
            size={24}
            style={{ color: 'var(--chakra-colors-gray-15)' }}
          />
        </IconButton>
        <div className={s.fadeContainer}>
          {ctaItems.map((item, index) => (
            <div
              key={index}
              className={clsx(s.fadeItem, {
                [s.visible]: index === active
              })}
            >
              {item.title}
            </div>
          ))}
        </div>
        {/*<AnimatePresence mode="wait">*/}
        {/*  <motion.div*/}
        {/*    key={ctaItems[active].id}*/}
        {/*    transition={{ duration: 0.4 }}*/}
        {/*    initial={{ opacity: 0, transform: 'translateY(12px) scale(0.8)' }}*/}
        {/*    animate={{ opacity: 1, transform: 'translateY(0px)' }}*/}
        {/*    exit={{ opacity: 0, transform: 'translateY(-12px) scale(0.8)' }}*/}
        {/*    style={{ textAlign: 'center' }}*/}
        {/*  >*/}
        {/*    {ctaItems[active]?.title}*/}
        {/*  </motion.div>*/}
        {/*</AnimatePresence>*/}
        <IconButton
          _hover={{
            filter: 'brightness(120%)'
          }}
          aria-label="right"
          onClick={() => {
            setActive(prev => {
              if (prev === ctaItems.length - 1) {
                return 0;
              }

              return prev + 1;
            });
          }}
        >
          <FaChevronRight
            size={24}
            style={{ color: 'var(--chakra-colors-gray-15)' }}
          />
        </IconButton>
      </Center>
    </Box>
  );
};
