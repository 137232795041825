import React, { FC } from 'react';
import { Box, Text } from '@chakra-ui/react';
import { MenuItem, MenuList } from '@chakra-ui/menu';
import { useToggle } from 'react-use';
import { AnimateHeight } from '@app/components/AnimateHeight';
import clsx from 'clsx';
import s from '@app/components/AppHeaderMenu/AppHeaderMenu.module.scss';
import { Icon } from '@app/components/Icon';
import Link from 'next/link';

interface Props {
  item: {
    label: string;
    show: boolean | undefined;
    items?: {
      label: string;
      link: string;
      show: boolean | undefined;
    }[];
  };
}

const variants = {
  open: {
    opacity: 1,
    height: 'auto'
  },
  collapsed: { opacity: 0, height: 0 }
};

const ExpandableOption: FC<Props> = ({ item }) => {
  const [open, toggleOpen] = useToggle(false);

  return (
    <Box
      key={item.label}
      display={item.show ? 'flex' : 'none'}
      sx={{
        flexDirection: 'column'
      }}
    >
      <Text
        color="gray.15"
        fontSize="20px"
        fontWeight={500}
        _hover={{ color: 'orange' }}
        onClick={toggleOpen}
        display="flex"
        alignItems="center"
        cursor="pointer"
      >
        {item.label} <Icon name="chevronDown" style={{ color: 'gray.15' }} />
      </Text>
      <AnimateHeight
        ease="easeOut"
        variants={variants}
        duration={0.3}
        isVisible={open}
      >
        {item.items?.map(subItem => (
          <MenuItem
            key={subItem.label}
            display={subItem.show ? 'flex' : 'none'}
            my={4}
            ml={5}
            _hover={{ color: 'orange' }}
          >
            <Link href={subItem.link}>
              <Text
                color="gray.15"
                fontSize="20px"
                fontWeight={500}
                _hover={{ color: 'orange' }}
              >
                {subItem.label}
              </Text>
            </Link>
          </MenuItem>
        ))}
      </AnimateHeight>
    </Box>
  );
};

export default ExpandableOption;
