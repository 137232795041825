import React, { FC, ReactNode } from 'react';
import { Center, Text } from '@chakra-ui/react';
import { useConfig } from '@app/config';

interface Props {
  content: ReactNode;
}

const AppAnnouncement: FC<Props> = ({ content }) => {
  const config = useConfig();

  if (!config?.FLAGS.showAnnouncement) {
    return null;
  }

  return (
    <Center
      sx={{
        width: '100%',
        backgroundColor: 'white',
        padding: '2px 4px'
      }}
    >
      <Text
        fontSize={['12px', '18px']}
        color="black"
        fontWeight={500}
        margin={0}
        textAlign="center"
      >
        {content}
      </Text>
    </Center>
  );
};

export default AppAnnouncement;
