import React, { FC } from 'react';

import { TransactionV4 } from '@app/types/transaction';
import { SwapTransactionV4 } from '@app/components/TransactionsHistory/components/SwapTransaction/SwapTransactionV4';
import { AddLiquidityTransactionV4 } from '@app/components/TransactionsHistory/components/AddLiquidityTransaction/AddLiquidityTransactionV4';

interface Props {
  data: TransactionV4;
}

export const TransactionItemV4: FC<Props> = ({ data }) => {
  if (data.swaps?.length) {
    return (
      <SwapTransactionV4
        txId={data.id}
        swaps={data.swaps}
        timestamp={data.timestamp}
      />
    );
  }

  if (data.mints?.length) {
    return (
      <AddLiquidityTransactionV4
        txId={data.id}
        mints={data.mints}
        timestamp={data.timestamp}
      />
    );
  }

  // if (data.burns?.length) {
  //   return (
  //     <RemoveLiquidityTransaction
  //       txId={data.id}
  //       burns={data.burns}
  //       timestamp={data.timestamp}
  //     />
  //   );
  // }

  return null;
};
