import { useNetworkMismatch, useSwitchChain } from '@thirdweb-dev/react';
import { useAsync } from 'react-use';
import { getActiveChain } from '@app/constants/chains';
import { useUserState } from '@app/state/userStore';

export function useSupportedNetworkSwitch() {
  const isMismatchNetwork = useNetworkMismatch();
  const switchChain = useSwitchChain();
  const { bridgeMode } = useUserState();

  useAsync(async () => {
    if (isMismatchNetwork && !bridgeMode) {
      await switchChain(getActiveChain().chainId);
    }
  }, [isMismatchNetwork, bridgeMode]);
}
