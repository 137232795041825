import { Box, VStack, Text } from '@chakra-ui/react';
import React from 'react';
import Logo from '@app/assets/images/logo/mainLogo.svg';
import Background from '@app/assets/images/bg/background.png';

export const ConnectWalletWelcomeScreen = () => {
  return (
    <Box
      p="24px"
      bg="gray.8"
      h="100%"
      backgroundImage={`url(${Background.src})`}
      backgroundSize="cover"
    >
      <VStack alignItems="flex-start" justifyContent="space-between" h="100%">
        <Logo />
        <VStack alignItems="flex-start">
          <Text fontSize="32px" fontWeight="500" color="neutral.200" mb="8px">
            Connect Wallet
          </Text>
          <Text fontSize="16px" fontWeight="400" color="neutral.200">
            Select your wallet from the options to get started.
          </Text>
        </VStack>
      </VStack>
    </Box>
  );
};
