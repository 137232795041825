import { Flex, Box, Text } from '@chakra-ui/react';
import Image from 'next/image';
import React from 'react';

import noDataImg from '@app/assets/images/placeholder/noData.png';

export const NoDataView = () => {
  return (
    <Flex
      height="100%"
      flexDirection="column"
      alignItems="center"
      justifyContent="center"
    >
      <Box>
        <Image src={noDataImg.src} width={900} height={200} alt="NoData" />
      </Box>
      <Box>
        <Text
          sx={{
            fontSize: '18px',
            fontWeight: 500,
            color: 'neutral.200',
            my: '16px',
            textAlign: 'center',
            fontFamily: 'p'
          }}
        >
          There are no transactions yet
        </Text>
      </Box>
    </Flex>
  );
};
