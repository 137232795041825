import { useInfiniteQuery } from '@tanstack/react-query';
import { request, gql } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { Transaction } from '@app/types/transaction';
import { getConfig } from '@app/config';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export type ViewFilter = 'all' | 'swap' | 'stake' | 'liquidity';

type QueryParams = {
  view: ViewFilter;
  address: string | undefined;
};

type PageParams = {
  skip: number;
};

const PAGE_SIZE = 20;

function getQuery(
  view: ViewFilter,
  pageParams: PageParams,
  queryParams: QueryParams
) {
  switch (view) {
    case 'swap': {
      return gql`
      query {
        transactions(
          orderBy: timestamp
          orderDirection: desc
          skip: ${pageParams?.skip ?? 0},
          first: ${PAGE_SIZE},
          where: {
            swaps_: { from: "${queryParams.address?.toLowerCase()}" }
          }
        ) {
          id
          timestamp
          swaps {
            amount0In
            amount0Out
            amount1Out
            amount1In
            pair {
              token0 {
                symbol
              }
              token1 {
                symbol
              }
            }
          }
        }
      }
    `;
    }
    case 'liquidity': {
      return gql`
      query {
        transactions(
          orderBy: timestamp
          orderDirection: desc
          skip: ${pageParams?.skip ?? 0},
          first: ${PAGE_SIZE},
          where: {
            or: [
              {
                mints_: { to: "${queryParams.address?.toLowerCase()}" }
              }
            ]
          }
        ) {
          id
          timestamp
          mints {
            id
            amount0
            amount1
            pair {
              token0 {
                symbol
              }
              token1 {
                symbol
              }
            }
          }
        }
      }
    `;
    }
    default: {
      return gql`
      query {
        transactions(
          orderBy: timestamp
          orderDirection: desc
          skip: ${pageParams?.skip ?? 0},
          first: ${PAGE_SIZE},
          where: {
            or: [
              {
                mints_: { to: "${queryParams.address?.toLowerCase()}" }
              },
              {
                swaps_: { from: "${queryParams.address?.toLowerCase()}" }
              }
            ]
          }
        ) {
          id
          timestamp
          mints {
            id
            amount0
            amount1
            pair {
              token0 {
                symbol
              }
              token1 {
                symbol
              }
            }
          }
          swaps {
            amount0In
            amount0Out
            amount1Out
            amount1In
            pair {
              token0 {
                symbol
              }
              token1 {
                symbol
              }
            }
          }
        }
      }
    `;
    }
  }
}

const fetcher = async (
  pageParams: PageParams,
  queryParams: QueryParams,
  chainId: number | undefined
) => {
  const query = getQuery(queryParams.view, pageParams, queryParams);

  const subgraphUrl = getConfig(chainId)?.URLS.subgraphUrl;

  if (!subgraphUrl) {
    return {
      transactions: []
    };
  }

  const res = await request<{ transactions: Transaction[] }>(
    `${subgraphUrl}`,
    query,
    queryParams
  );

  return {
    transactions: res?.transactions
  };
};

export function useInfiniteTransactionsHistory(params: QueryParams) {
  const chainId = useSupportedChain()?.chainId;

  const {
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch
  } = useInfiniteQuery<{
    transactions: Transaction[];
  }>(
    [QueryKeys.TRANSACTIONS, { params, chainId }],
    ({ pageParam }) => {
      return fetcher(pageParam, params, chainId);
    },
    {
      getNextPageParam: (lastPage, allPages) => {
        return {
          skip: allPages.length * PAGE_SIZE
        };
      },
      staleTime: 60000,
      refetchOnWindowFocus: false,
      enabled: !!params.address
    }
  );

  const dataFlat = data?.pages.reduce<Transaction[]>((res, page) => {
    res.push(...page.transactions);

    return res;
  }, []);

  const totalLoaded =
    data?.pages.reduce((res, page) => {
      const pageLength = page.transactions.length;

      return res + pageLength;
    }, 0) ?? 0;

  return {
    dataFlat,
    totalLoaded,
    data,
    error,
    fetchNextPage,
    hasNextPage,
    isFetching,
    isFetchingNextPage,
    status,
    refetch
  };
}
