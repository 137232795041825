import { Heading } from '@chakra-ui/react';
import Link from 'next/link';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

interface Props {
  link: string;
  title: string;
}

export const AppHeaderLink: FC<Props> = ({ link, title }) => {
  const router = useRouter();

  const isActive = router.pathname.includes(link);

  return (
    <Link href={link}>
      <Heading
        as="h3"
        px="0"
        bgColor="transparent"
        fontSize="16px"
        textAlign="center"
        transition="all 0.2s ease"
        _hover={{ color: 'brand.primary' }}
        fontWeight="400"
        color={isActive ? 'brand.primary' : 'brand.secondary'}
        borderBottom="1px solid transparent"
        borderColor={isActive ? 'brand.primary' : 'transparent'}
      >
        {title}
      </Heading>
    </Link>
  );
};

export default AppHeaderLink;
