import { AspectRatio, ChakraProps, Circle, Text } from '@chakra-ui/react';
import React, { FC, useMemo } from 'react';

import { CHAIN_ICONS } from '@app/constants/chains';

interface Props extends ChakraProps {
  name?: string;
  iconUrl?: string | null;
  width?: string;
  height?: string;
}

export const NetworkIcon: FC<Props> = ({
  name,
  iconUrl,
  width = '24px',
  height = '24px',
  ...rest
}) => {
  const url = useMemo(() => {
    if (iconUrl) {
      return iconUrl;
    }

    if (CHAIN_ICONS[name?.toLowerCase() ?? '']) {
      return CHAIN_ICONS[name?.toLowerCase() ?? ''].src;
    }

    return null;
  }, [iconUrl, name]);

  return (
    <AspectRatio
      ratio={1}
      w={width}
      h={height}
      pos="relative"
      borderRadius="100%"
      overflow="hidden"
      border="1px solid"
      borderColor="neutral.400"
      {...rest}
    >
      {url ? (
        <img
          src={url}
          style={{ width: '24px', height: '24px' }}
          alt="Network logo"
        />
      ) : (
        <Circle size="100%" bg="neutral.400" color="black">
          <Text fontSize="10px" fontWeight={600}>
            {name?.slice(0, 3)}
          </Text>
        </Circle>
      )}
    </AspectRatio>
  );
};

export default NetworkIcon;
