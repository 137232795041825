import { Heading } from '@chakra-ui/react';
import { useRouter } from 'next/router';
import React, { FC } from 'react';

interface Props {
  link: string;
  title: string;
}

export const AppHeaderLink: FC<Props> = ({ link, title }) => {
  const router = useRouter();

  const isActive = router.pathname.includes(link);

  return (
    <a href={link} target="_blank" rel="noreferrer">
      <Heading
        as="h3"
        px="10px"
        py="7px"
        bg="secondary.darkOrange"
        borderRadius={[0, '10px']}
        border="1px solid"
        borderColor="orange"
        minWidth="92px"
        fontSize="16px"
        textAlign="center"
        transition="all 0.2s ease"
        _hover={{ filter: 'brightness(120%)' }}
        fontWeight="400"
        color="orange"
      >
        {title}
      </Heading>
    </a>
  );
};

export default AppHeaderLink;
