import React, { useEffect } from 'react';
import {
  useAddress,
  useChain,
  useSupportedChains,
  useSwitchChain
} from '@thirdweb-dev/react';
import { Menu, MenuButton, MenuItem, MenuList } from '@chakra-ui/menu';
import { Avatar, Box, Button, HStack, Text } from '@chakra-ui/react';
import { ACTIVE_CHAIN } from '@app/constants/common';
import { useChainState } from '@app/state/chainStore';
import { NetworkIcon } from '@app/components/NetworkIcon';

export const NetworkSelector = () => {
  const address = useAddress();
  const chain = useChain();

  const supportedChains = useSupportedChains();
  const switchChain = useSwitchChain();

  const {
    actions: { setActiveChainId }
  } = useChainState();

  useEffect(() => {
    const lsChainValue = localStorage.getItem(ACTIVE_CHAIN);

    if (chain?.chainId && lsChainValue && +lsChainValue !== chain.chainId) {
      localStorage.setItem(ACTIVE_CHAIN, chain.chainId.toString());
      setActiveChainId(chain.chainId);
    } else if (chain?.chainId && !lsChainValue) {
      localStorage.setItem(ACTIVE_CHAIN, chain.chainId.toString());
    }
  }, [chain, setActiveChainId]);

  if (!address) {
    return null;
  }

  return (
    <>
      <Menu placement="bottom-end">
        <MenuButton
          as={Button}
          sx={{
            padding: '8px 8px',
            background: 'gray.11',
            borderRadius: '8px',
            width: 'fit-content'
          }}
        >
          <Box sx={{ display: 'flex', alignItems: 'center', gap: '8px' }}>
            <NetworkIcon
              name={chain?.name}
              iconUrl={chain?.icon?.url.replace(
                'ipfs://',
                'https://ipfs.io/ipfs/'
              )}
            />
          </Box>
        </MenuButton>
        <MenuList
          sx={{
            backgroundColor: 'gray.12',
            borderRadius: '8px',
            minWidth: '200px',
            overflow: 'hidden'
          }}
        >
          {supportedChains.map(item => {
            return (
              <MenuItem
                key={item.chainId}
                minH="48px"
                p="12px"
                gap="12px"
                borderBottom="1px solid"
                borderColor="whiteOpacity.12"
                onClick={() => switchChain(item.chainId)}
              >
                <HStack width="100%">
                  <NetworkIcon
                    name={item?.name}
                    iconUrl={item?.icon?.url.replace(
                      'ipfs://',
                      'https://ipfs.io/ipfs/'
                    )}
                  />
                  <Text color="white" fontSize={14}>
                    {item.name}
                  </Text>
                </HStack>
              </MenuItem>
            );
          })}
        </MenuList>
      </Menu>
    </>
  );
};
