import { modalAnatomy as parts } from '@chakra-ui/anatomy';
import { createMultiStyleConfigHelpers } from '@chakra-ui/styled-system';

const { definePartsStyle, defineMultiStyleConfig } =
  createMultiStyleConfigHelpers(parts.keys);

const baseStyle = definePartsStyle({
  // define the part you're going to style
  overlay: {
    bg: 'blackAlpha.500',
    backdropFilter: '3px',
    zIndex: 110
  },
  dialogContainer: {
    zIndex: 120,
    overflow: 'auto'
  },
  dialog: {
    borderRadius: '16px',
    bg: `neutral.700`,
    px: '16px',
    py: '14px',
    height: 'fit-content',
    position: ['fixed', 'initial'],
    bottom: [0, 'unset'],
    margin: [0, '8rem auto']
  },
  dialogHeader: {
    display: 'block'
  }
});

export default defineMultiStyleConfig({
  baseStyle
});
