import React from 'react';
import { useLocationContext } from '@app/context/LocationContext';
import { Center, Text } from '@chakra-ui/react';

export const LocationWarningBanner = () => {
  const { toggleShowLocationWarning, isReadOnly } = useLocationContext();

  if (!isReadOnly) {
    return null;
  }

  return (
    <Center
      sx={{
        width: '100%',
        backgroundColor: 'neutral.500',
        padding: '2px 4px'
      }}
    >
      <Text fontSize="12px" color="primaryWhite" fontWeight={500}>
        Sorry, our products are unavailable in your location
      </Text>
    </Center>
  );
};
