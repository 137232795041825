import { useQuery, UseQueryOptions } from '@tanstack/react-query';
import { request, gql, Variables } from 'graphql-request';

import { QueryKeys } from 'src/constants/queryKeys';

import { AlgebraFactory } from '@app/types/pool';
import { useConfig } from '@app/config';
import { useChainId } from '@thirdweb-dev/react';

const fetcher = (
  baseUrl: string,
  params: Variables = {
    id: ''
  }
) => {
  return request<{ factory: AlgebraFactory }>(
    `${baseUrl}`,
    gql`
      query {
        factory(id: "${params.id}") {
          id
          totalVolumeUSD
          totalValueLockedUSD
        }
      }
    `,
    params
  );
};

export const useFactoryDataV3 = (
  params: Variables = {},
  options: UseQueryOptions<AlgebraFactory> = {}
) => {
  const config = useConfig();

  return useQuery<AlgebraFactory>(
    [QueryKeys.FACTORY_DATA, { params }],
    async () => {
      const response = await fetcher(config?.URLS.subgraphUrlV3 as string, {
        ...params
      });

      return response.factory;
    },
    {
      staleTime: 3000,
      refetchInterval: 15000,
      enabled: !!config,
      ...options
    }
  );
};
