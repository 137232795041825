import { HStack, Button, Box } from '@chakra-ui/react';
import { useAddress } from '@thirdweb-dev/react';
import React, { FC, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';

import { NoDataView } from '@app/components/TransactionsHistory/components/NoDataView';
import { ListLoader } from '@app/screens/pools/components/ListLoader';
import { useInfiniteTransactionsHistoryV4 } from '@app/hooks/history/useTransactionHistoryV4';
import { TransactionItemV4 } from '@app/components/TransactionsHistory/components/TransactionItem/TransactionItemV4';
import { useConfig } from '@app/config';

type ViewFilter = 'all' | 'swap' | 'stake' | 'liquidity';

interface Props {
  onClose: (nxtVal?: boolean) => void;
}

export const TransactionsHistoryV4: FC<Props> = ({ onClose }) => {
  const address = useAddress();
  const config = useConfig();
  const [filter, setFilter] = useState<ViewFilter>('all');

  const {
    dataFlat,
    hasNextPage,
    fetchNextPage,
    totalLoaded,
    status,
    isFetching
  } = useInfiniteTransactionsHistoryV4({
    view: filter,
    address
  });

  function renderFilterButton(name: ViewFilter, disabled: boolean = false) {
    const isActive = filter === name;

    if (disabled) {
      return null;
    }

    return (
      <Button
        isDisabled={disabled}
        variant="secondary"
        sx={{
          color: isActive ? 'brand.primary' : 'neutral.300',
          textTransform: 'capitalize',
          width: '100%'
        }}
        onClick={() => setFilter(name)}
      >
        {name}
      </Button>
    );
  }

  function renderContent() {
    if (status === 'loading') {
      return <ListLoader />;
    }

    if (dataFlat?.length) {
      return (
        <InfiniteScroll
          dataLength={totalLoaded}
          next={fetchNextPage}
          hasMore={hasNextPage ?? false}
          loader={<div />}
          scrollableTarget="scrollableDiv"
        >
          {dataFlat?.map(item => {
            return <TransactionItemV4 key={item.id} data={item} />;
          })}
        </InfiniteScroll>
      );
    }

    return <NoDataView />;
  }

  return (
    <>
      <HStack
        justifyContent="space-between"
        alignItems="center"
        p="0 24px 24px"
      >
        {renderFilterButton('all')}
        {renderFilterButton('swap')}
        {renderFilterButton('liquidity')}
      </HStack>
      <Box
        sx={{ padding: '24px' }}
        id="scrollableDiv"
        overflowY="auto"
        height="calc(100dvh - 200px)"
      >
        {renderContent()}
      </Box>
    </>
  );
};
