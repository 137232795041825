import { Box, HStack, Text, VStack } from '@chakra-ui/react';
import cn from 'clsx';
import Link from 'next/link';
import React from 'react';

import AppLogo from '@app/assets/images/logo/mainLogo.svg';
import { footerNav, socials } from '@app/components/_AppFooter/options';
import { toOptionalFixed } from '@app/helpers/format';

import s from './AppFooter.module.scss';
import { RpcSelector } from '@app/components/RpcSelector';
import { useTotalAmmTvl } from '@app/hooks/other/useTotalAmmTvl';
import { useSupportedChain } from '@app/hooks/other/useSupportedChain';

export const AppFooter: React.FC = () => {
  const { data: totalAmmTvl, isLoading: isLoadingTotalTvl } = useTotalAmmTvl();

  const chainId = useSupportedChain()?.chainId;

  return (
    <Box px="20px" mt="64px" bgColor="brand.black" boxShadow="lg" zIndex={1}>
      <Box className={s.root}>
        <VStack align="flex-start">
          <AppLogo />
          <HStack>
            <HStack mt="auto">
              {socials
                .filter(option => !!option.link)
                .map((option, i) => {
                  return (
                    <li
                      className={cn(s.listItem, {
                        [s.disabled]: !option.link
                      })}
                      key={i}
                    >
                      {option.isExternal ? (
                        <a href={option.link} target="_blank" rel="noreferrer">
                          {option.img}
                        </a>
                      ) : (
                        <a href={option.link}>{option.img}</a>
                      )}
                    </li>
                  );
                })}
            </HStack>
            {totalAmmTvl && (
              <Box className={s.socialInfo}>
                <Text fontSize="16px" color="brand.secondary">
                  TVL - ${totalAmmTvl ? toOptionalFixed(totalAmmTvl, 2) : 'n/a'}
                </Text>
              </Box>
            )}
          </HStack>
        </VStack>
        <div className={s.nav}>
          {footerNav(chainId).map(topic => {
            return (
              <Box key={topic.title}>
                <h4 className={s.topicTitle}>{topic.title}</h4>
                <ul className={s.list}>
                  {topic.options
                    .filter(option => !!option.link)
                    .map((option, i) => {
                      return (
                        <li
                          className={cn(s.listItem, {
                            [s.disabled]: !option.link
                          })}
                          key={i}
                        >
                          {option.isExternal ? (
                            <Link
                              href={option.link}
                              target="_blank"
                              rel="noreferrer"
                            >
                              {option.label}
                            </Link>
                          ) : (
                            <Link href={option.link}>{option.label}</Link>
                          )}
                        </li>
                      );
                    })}
                </ul>
              </Box>
            );
          })}
          <Box width="100%" sx={{ alignItems: 'flex-end' }}>
            <RpcSelector />
          </Box>
        </div>
      </Box>
    </Box>
  );
};
