import process from 'process';
import { ModeEnvs } from '@app/constants/chains';
import { Scroll, ScrollSepoliaTestnet } from '@thirdweb-dev/chains';
import { ApplicationConfig } from '@app/config/types';
import { scrollMainnetConfig } from '@app/config/scrollMainnetConfig';
import { scrollTestnetConfig } from '@app/config/scrollTestnetConfig';

export const envConfig = {
  modeEnv: (process.env.NEXT_PUBLIC_MODE_ENV ?? 'TESTNET') as ModeEnvs,
  thirdwebClientId: process.env.NEXT_PUBLIC_THIRDWEB_CLIENT_ID,
  useBase: false
};

const APP_CONFIG: Record<number, ApplicationConfig> = {
  [Scroll.chainId]: scrollMainnetConfig,
  [ScrollSepoliaTestnet.chainId]: scrollTestnetConfig
};

export function getConfig(
  chainId: number | undefined = Scroll.chainId
): ApplicationConfig | undefined {
  return APP_CONFIG[chainId];
}

export function useConfig(_chainId?: number) {
  return getConfig(Scroll.chainId);
}
