export const META_TAGS_DEFAULT_CONFIG = {
  twitterCardType: 'summary_large_image',
  twitterCreator: '',
  twitterImageAlt: '',
  twitterSite: '',
  title: 'Scribe DEX | Swap & Provide Liquidity',
  description:
    'Swap tokens or provide liquidity seamlessly on Scribe DEX. Enjoy low fees and fast transactions on the Scroll Network.',
  url: 'https://app.scribe.exchange',
  image: 'https://app.scribe.exchange/assets/images/banner.png',
  logo: 'https://app.scribe.exchange/assets/images/logo.svg'
};
