import React, {
  createContext,
  FC,
  PropsWithChildren,
  useContext,
  useEffect,
  useMemo
} from 'react';
import { useAddress } from '@thirdweb-dev/react';
import { useLogin } from '@app/hooks/user/useLogin';
import { UserWallet, useUserWallet } from '@app/hooks/user/useUserWallet';
import { useRouter } from 'next/router';
import { useEligibleToReferral } from '@app/hooks/points/useEligibleToReferral';
import { KIM_REF_CODES } from '@app/constants/common';

interface IAuthContext {
  isAuthorized: boolean;
  login: ({
    address,
    referralCode,
    chainId
  }: {
    address: string | undefined;
    referralCode?: string;
    chainId: number | undefined;
  }) => Promise<boolean | undefined>;
  user: UserWallet | null;
  eligibleToReferral:
    | {
        isEligible: boolean;
        error: null | string;
      }
    | undefined;
}

const AuthContext = createContext<IAuthContext>({
  isAuthorized: false,
  login: () => Promise.resolve(true),
  user: null,
  eligibleToReferral: undefined
});

export function useAuthContext() {
  const context = useContext(AuthContext);

  if (context === undefined) {
    throw new Error('useAuthContext must be used withing AuthContextProvider');
  }

  return context;
}

export const AuthContextProvider: FC<PropsWithChildren> = ({ children }) => {
  const { query } = useRouter();
  const address = useAddress();
  const referralCode =
    query.referralCode !== undefined
      ? (query.referralCode as string)
      : undefined;

  const { mutateAsync: login, isLoading: isLoginInProgress } = useLogin();

  const { data, isLoading } = useUserWallet();

  const { data: eligible } = useEligibleToReferral();

  useEffect(() => {
    if (referralCode && address) {
      const exisitng = localStorage.getItem(KIM_REF_CODES);

      let existingCodes = {};

      if (exisitng) {
        existingCodes = JSON.parse(exisitng);
      }

      localStorage.setItem(
        KIM_REF_CODES,
        JSON.stringify({
          ...existingCodes,
          [address]: referralCode
        })
      );
    }
  }, [address, referralCode]);

  const contextValue = useMemo(() => {
    return {
      isAuthorized: typeof data !== 'string' && !!data?.id,
      login,
      user: typeof data !== 'string' && data ? data : null,
      eligibleToReferral: eligible
    };
  }, [data, eligible, login]);

  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};
