/* eslint-disable */
// This file was generated by script
// this file is used to generate icon names from files within this directory
// icons are used in Icon component

import addUser from './add-user.svg';
import add from './add.svg';
import alarmClock from './alarm-clock.svg';
import appleCalendar from './apple-calendar.svg';
import arrowDownLeft from './arrow-down-left.svg';
import arrowDownRight from './arrow-down-right.svg';
import arrowDown from './arrow-down.svg';
import arrowLeft from './arrow-left.svg';
import arrowRight from './arrow-right.svg';
import arrowTopLeft from './arrow-top-left.svg';
import arrowTopRight from './arrow-top-right.svg';
import arrowUp from './arrow-up.svg';
import arrowRightFilled from './arrowRightFilled.svg';
import bag2 from './bag-2.svg';
import bag3 from './bag-3.svg';
import bag from './bag.svg';
import basketball from './basketball.svg';
import bell from './bell.svg';
import bitcoin from './bitcoin.svg';
import book from './book.svg';
import bookLink from './bookLink.svg';
import bookmark from './bookmark.svg';
import boostNoFill from './boost-no-fill.svg';
import boost from './boost.svg';
import boosted from './boosted.svg';
import boxBookmark from './box-bookmark.svg';
import box from './box.svg';
import browser from './browser.svg';
import bullet from './bullet.svg';
import burger from './burger.svg';
import calc from './calc.svg';
import calculator from './calculator.svg';
import calendar from './calendar.svg';
import callMissed from './call-missed.svg';
import callSilent from './call-silent.svg';
import call from './call.svg';
import calling from './calling.svg';
import camera from './camera.svg';
import cart from './cart.svg';
import category2 from './category-2.svg';
import category from './category.svg';
import chartBullet from './chart-bullet.svg';
import chart from './chart.svg';
import check from './check.svg';
import checkCircle from './checkCircle.svg';
import chevronDown from './chevron-down.svg';
import chevronLeft from './chevron-left.svg';
import chevronRight from './chevron-right.svg';
import chevronUp from './chevron-up.svg';
import circleDownArrow from './circle-down-arrow.svg';
import circleDownChevron from './circle-down-chevron.svg';
import circleDownLeftArrow from './circle-down-left-arrow.svg';
import circleDownRightArrow from './circle-down-right-arrow.svg';
import circleLeftArrow from './circle-left-arrow.svg';
import circleLeftChevron from './circle-left-chevron.svg';
import circleRightArrow from './circle-right-arrow.svg';
import circleRightChevron from './circle-right-chevron.svg';
import circleUpArrow from './circle-up-arrow.svg';
import circleUpChevron from './circle-up-chevron.svg';
import circleUpLeftArrow from './circle-up-left-arrow.svg';
import circleUpRightArrow from './circle-up-right-arrow.svg';
import close from './close.svg';
import closeCircle from './closeCircle.svg';
import cloudDownload from './cloud-download.svg';
import cloudUpload from './cloud-upload.svg';
import cloud from './cloud.svg';
import coffee from './coffee.svg';
import coin from './coin.svg';
import coins from './coins.svg';
import collapse from './collapse.svg';
import colorPalette from './color-palette.svg';
import compass from './compass.svg';
import copy from './copy.svg';
import core from './core.svg';
import coreBitcoin from './coreBitcoin.svg';
import coreBlockExplorer from './coreBlockExplorer.svg';
import coreBridge from './coreBridge.svg';
import coreContractVerifier from './coreContractVerifier.svg';
import coreCurrency from './coreCurrency.svg';
import coreDelegators from './coreDelegators.svg';
import coreFaucet from './coreFaucet.svg';
import corePie from './corePie.svg';
import coreRpcEndpoint from './coreRpcEndpoint.svg';
import coreStaking from './coreStaking.svg';
import coreUniswap from './coreUniswap.svg';
import coupon1 from './coupon-1.svg';
import coupon3 from './coupon-3.svg';
import coupon from './coupon.svg';
import creditCard from './credit-card.svg';
import danger from './danger.svg';
import defaultCalendar from './default-calendar.svg';
import disconnect from './disconnect.svg';
import dislike from './dislike.svg';
import documentAdd from './document-add.svg';
import documentDelete from './document-delete.svg';
import document from './document.svg';
import download from './download.svg';
import edit2 from './edit-2.svg';
import edit from './edit.svg';
import ellipsis from './ellipsis.svg';
import error from './error.svg';
import ethersjs from './ethersjs.svg';
import event from './event.svg';
import events from './events.svg';
import expand from './expand.svg';
import externalLink from './externalLink.svg';
import eye from './eye.svg';
import fantastical from './fantastical.svg';
import figma from './figma.svg';
import fileDoc from './file-doc.svg';
import filePdf from './file-pdf.svg';
import fileTxt from './file-txt.svg';
import fileZip from './file-zip.svg';
import file from './file.svg';
import filterLines from './filter-lines.svg';
import filter from './filter.svg';
import fire from './fire.svg';
import flag from './flag.svg';
import flame from './flame.svg';
import folder from './folder.svg';
import gamepadOldschool from './gamepad-oldschool.svg';
import gamepad from './gamepad.svg';
import gift from './gift.svg';
import github from './github.svg';
import gnosisSafe from './gnosisSafe.svg';
import googleCalendar from './google-calendar.svg';
import graph from './graph.svg';
import hardhat from './hardhat.svg';
import headphone from './headphone.svg';
import heartbeat from './heartbeat.svg';
import hearth from './hearth.svg';
import hide from './hide.svg';
import home from './home.svg';
import image from './image.svg';
import infoCircle from './info-circle.svg';
import infoSquare from './info-square.svg';
import info from './info.svg';
import ipfsp2p from './ipfsp2p.svg';
import iphone from './iphone.svg';
import javaweb3 from './javaweb3.svg';
import laptop from './laptop.svg';
import layers from './layers.svg';
import lightning from './lightning.svg';
import like from './like.svg';
import link2 from './link-2.svg';
import link from './link.svg';
import loading from './loading.svg';
import location from './location.svg';
import lockCheck from './lock-check.svg';
import lockKey from './lock-key.svg';
import lockOpen from './lock-open.svg';
import lockX from './lock-x.svg';
import lock from './lock.svg';
import lockFailed from './lockFailed.svg';
import login from './login.svg';
import logout from './logout.svg';
import mail from './mail.svg';
import marketMaker from './market-maker.svg';
import messageCircle from './message-circle.svg';
import messageSquare from './message-square.svg';
import metamask from './metamask.svg';
import microphoneMuted from './microphone-muted.svg';
import microphone from './microphone.svg';
import microsoftOutlook from './microsoft-outlook.svg';
import minus from './minus.svg';
import minusCircle from './minusCircle.svg';
import moreCircle from './more-circle.svg';
import moreSquare from './more-square.svg';
import mouse from './mouse.svg';
import musicPlate from './music-plate.svg';
import music from './music.svg';
import newPool from './newPool.svg';
import paper from './paper.svg';
import paperclip from './paperclip.svg';
import pause from './pause.svg';
import play from './play.svg';
import plus from './plus.svg';
import plusCircle from './plusCircle.svg';
import points from './points.svg';
import radiobutton from './radiobutton.svg';
import receipt from './receipt.svg';
import refresh from './refresh.svg';
import remix from './remix.svg';
import rewardsNoFill from './rewards-no-fill.svg';
import rewards from './rewards.svg';
import roadmap from './roadmap.svg';
import rocket from './rocket.svg';
import sale from './sale.svg';
import save from './save.svg';
import scale from './scale.svg';
import scanner from './scanner.svg';
import screen from './screen.svg';
import search from './search.svg';
import send from './send.svg';
import settings from './settings.svg';
import share from './share.svg';
import shieldCheck from './shield-check.svg';
import shieldClose from './shield-close.svg';
import shield from './shield.svg';
import sign from './sign.svg';
import signature from './signature.svg';
import socialDiscord from './social-discord.svg';
import socialLinkedin from './social-linkedin.svg';
import socialMedium from './social-medium.svg';
import socialTelegram from './social-telegram.svg';
import socialTwitter from './social-twitter.svg';
import socialWebsite from './social-website.svg';
import solidity from './solidity.svg';
import squareDownArrow from './square-down-arrow.svg';
import squareDownChevron from './square-down-chevron.svg';
import squareLeftArrow from './square-left-arrow.svg';
import squareLeftChevron from './square-left-chevron.svg';
import squareRightArrow from './square-right-arrow.svg';
import squareRightChevron from './square-right-chevron.svg';
import squareUpArrow from './square-up-arrow.svg';
import squareUpChevron from './square-up-chevron.svg';
import star from './star.svg';
import stop from './stop.svg';
import substract from './substract.svg';
import suitcase from './suitcase.svg';
import swap from './swap.svg';
import timeCircle from './time-circle.svg';
import timeSquare from './time-square.svg';
import timer from './timer.svg';
import toggleLeft from './toggle-left.svg';
import toggleRight from './toggle-right.svg';
import trash from './trash.svg';
import trxHistory from './trxHistory.svg';
import tvl from './tvl.svg';
import unlock from './unlock.svg';
import upload from './upload.svg';
import user from './user.svg';
import users from './users.svg';
import verified from './verified.svg';
import video from './video.svg';
import volumeOff from './volume-off.svg';
import volumeUp from './volume-up.svg';
import volume from './volume.svg';
import voulmeDown from './voulme-down.svg';
import wallet from './wallet.svg';
import warning from './warning.svg';
import web3Provider from './web3Provider.svg';
import web3js from './web3js.svg';
import web3py from './web3py.svg';
import webLink from './webLink.svg';
import zeppelin from './zeppelin.svg';
import zoomIn from './zoom-in.svg';
import zoomOut from './zoom-out.svg';

const icons = {
addUser,
add,
alarmClock,
appleCalendar,
arrowDownLeft,
arrowDownRight,
arrowDown,
arrowLeft,
arrowRight,
arrowTopLeft,
arrowTopRight,
arrowUp,
arrowRightFilled,
bag2,
bag3,
bag,
basketball,
bell,
bitcoin,
book,
bookLink,
bookmark,
boostNoFill,
boost,
boosted,
boxBookmark,
box,
browser,
bullet,
burger,
calc,
calculator,
calendar,
callMissed,
callSilent,
call,
calling,
camera,
cart,
category2,
category,
chartBullet,
chart,
check,
checkCircle,
chevronDown,
chevronLeft,
chevronRight,
chevronUp,
circleDownArrow,
circleDownChevron,
circleDownLeftArrow,
circleDownRightArrow,
circleLeftArrow,
circleLeftChevron,
circleRightArrow,
circleRightChevron,
circleUpArrow,
circleUpChevron,
circleUpLeftArrow,
circleUpRightArrow,
close,
closeCircle,
cloudDownload,
cloudUpload,
cloud,
coffee,
coin,
coins,
collapse,
colorPalette,
compass,
copy,
core,
coreBitcoin,
coreBlockExplorer,
coreBridge,
coreContractVerifier,
coreCurrency,
coreDelegators,
coreFaucet,
corePie,
coreRpcEndpoint,
coreStaking,
coreUniswap,
coupon1,
coupon3,
coupon,
creditCard,
danger,
defaultCalendar,
disconnect,
dislike,
documentAdd,
documentDelete,
document,
download,
edit2,
edit,
ellipsis,
error,
ethersjs,
event,
events,
expand,
externalLink,
eye,
fantastical,
figma,
fileDoc,
filePdf,
fileTxt,
fileZip,
file,
filterLines,
filter,
fire,
flag,
flame,
folder,
gamepadOldschool,
gamepad,
gift,
github,
gnosisSafe,
googleCalendar,
graph,
hardhat,
headphone,
heartbeat,
hearth,
hide,
home,
image,
infoCircle,
infoSquare,
info,
ipfsp2p,
iphone,
javaweb3,
laptop,
layers,
lightning,
like,
link2,
link,
loading,
location,
lockCheck,
lockKey,
lockOpen,
lockX,
lock,
lockFailed,
login,
logout,
mail,
marketMaker,
messageCircle,
messageSquare,
metamask,
microphoneMuted,
microphone,
microsoftOutlook,
minus,
minusCircle,
moreCircle,
moreSquare,
mouse,
musicPlate,
music,
newPool,
paper,
paperclip,
pause,
play,
plus,
plusCircle,
points,
radiobutton,
receipt,
refresh,
remix,
rewardsNoFill,
rewards,
roadmap,
rocket,
sale,
save,
scale,
scanner,
screen,
search,
send,
settings,
share,
shieldCheck,
shieldClose,
shield,
sign,
signature,
socialDiscord,
socialLinkedin,
socialMedium,
socialTelegram,
socialTwitter,
socialWebsite,
solidity,
squareDownArrow,
squareDownChevron,
squareLeftArrow,
squareLeftChevron,
squareRightArrow,
squareRightChevron,
squareUpArrow,
squareUpChevron,
star,
stop,
substract,
suitcase,
swap,
timeCircle,
timeSquare,
timer,
toggleLeft,
toggleRight,
trash,
trxHistory,
tvl,
unlock,
upload,
user,
users,
verified,
video,
volumeOff,
volumeUp,
volume,
voulmeDown,
wallet,
warning,
web3Provider,
web3js,
web3py,
webLink,
zeppelin,
zoomIn,
zoomOut,
};

export default icons;
